.profile-section {
  min-height: 95vh;
  padding: 10px 110px;
}
.profile-section h2 {
  margin: 30px 0px 50px 0px;
  font-size: 35px;
  font-weight: 600;
  text-transform: capitalize;
  letter-spacing: 1px;
}
.profile-blocks {
  height: max-content;
  display: flex;
  gap: 30px;
  width: 100% !important;
}
.profile-blocks > div {
  flex: 1;
  height: 200px;
  background-color: black;
}
.profile-block {
  background: linear-gradient(
    149deg,
    rgba(0, 111, 180, 0.9) 18.65%,
    rgba(0, 155, 64, 0.9) 96.89%
  );
  padding: 44px;
  color: white;
}
.profile-block > h3,
p {
  text-align: center;
  font-size: 14px;
}
.profile-block p {
  font-family: Montserrat;
  font-size: 15px;
}
.block-content{
  font-family: 900;
  font-size: 14px !important;
}
.image-block {
  flex: 0.9 !important;

  width: max-content !important;
  min-height: 481px !important;
}
.image-block img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.first-profile-block {
  margin-top: 50px !important;
}
.second-profile-block {
  min-height: 580px !important;
}
.third-profile-block {
  height: 408px !important;

  margin-top: 205px;
}
.fourth-profile-block {
  height: 359px !important;

  margin-left: 20px;
}
.fifth-profile-block {
  height: 243px !important;
  margin-top: 30px;
}
.sixth-profile-block {
  margin-top: 30px;
}
.eighth-profile-block{
  height: 400px !important;
}

@media only screen and (max-width: 1440px) {
  .image-block {
    /* flex: 0.6 !important; */
    width: max-content !important;
    min-height: 481px !important;
  }

  
}
@media only screen and (max-width: 1024px) {
  .profile-section {
    padding: 10px 65px;
  }
  .profile-block {
  
    padding: 19px;
   
  }
  .block-content{
    font-size: 14px !important;
  }
  .image-block{
    min-height: 377px !important;
  }
  .first-profile-block {
    margin-top: 50px !important;
  }
  .second-profile-block {
    min-height: 470px !important;
  }
  .third-profile-block {
    height: 296px !important;
  
    margin-top: 205px;
  }
  .fourth-profile-block {
    height: 359px !important;
  
    margin-left: 20px;
  }
  .fifth-profile-block {
    height: 243px !important;
    margin-top: 30px;
  }
  .sixth-profile-block {
    margin-top: 30px;
  }
 

}
@media only screen and (max-width: 768px) {
  .image-block {
    flex: 0.7 !important;
    min-height: 245px !important;
  }
  .profile-block h3
{
font-size: 15px;
}
.profile-block{
  padding: 8px;
}
.profile-block p {
  font-size: 7.9px !important;
  padding: 5px;
}
  .first-profile-block {
    margin-top: 50px !important;
  }
  .second-profile-block {
    min-height: 315px !important;
  }
  .third-profile-block {
    height: 195px !important;
    margin-top: 145px;
  }
  .fourth-profile-block {
    height: 220px !important;
    margin-left: 20px;
  }
  .fifth-profile-block {
    height: 145px !important;
    margin-top: 23px;
  }
  .sixth-profile-block {
    margin-top: 23px;
  }
  .seventh-profile-block{
    margin-top: 50px !important;
  }
  /* .eighth-profile-block{
    min-height: 320px !important; 
  } */
}
@media only screen and (max-width: 500px) {
  .profile-section {
    padding: 10px 30px;
  }
  .profile-head{
    text-align: center;
  }
  .profile-blocks {
    flex-direction: column;
    height: max-content !important;
    margin-bottom: 30px;
    align-items: center;
  }
  .profile-block {
    margin: 0 !important;
    width: 240px !important;
    min-height: max-content !important;
  }

  .image-block {
    width: 190px !important;
    margin: 0px !important;
  }
  .profile-section h2 {
    font-size: 28px;
  }
}
