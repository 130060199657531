/*clinet page */
body {
  overflow-x: hidden;
}

.cline-cont {
  margin-bottom: 10px;
}

.clinet-circle {
  margin: 76px 0px 40px -102px;
}

#circle {
  width: 600px;
  height: 600px;
  border: 2px solid #006FB4;
  border-radius: 50%;
}

.about-h1 {
  /* margin: 200px 0px 0px 150px !important; */
  margin: 155px 0px 0px 150px !important;
}

.clinet-para {
  margin: 30px 120px 0px 152px;
}

.lines {
  content: '';
  background-image: linear-gradient(180deg, #009B40 0%, #007A97 54.17%, #006FB4 100%);
  position: relative;
  top: 5%;
  left: 23.5%;
  bottom: 30px;
  width: 107px;
  height: 8px;
  border-radius: 20px;
}

.lines2 {
  content: '';
  background-image: linear-gradient(180deg, #009B40 0%, #007A97 54.17%, #006FB4 100%);
  ;
  position: relative;
  top: 1.0%;
  left: 43.6%;
  bottom: 30px;
  width: 52px;
  height: 8px;
  border-radius: 20px;
}

.line {
  border-left: 1px solid #006FB4 !important;
  height: 153%;
  position: absolute;
  left: 30.8%;
  top: -1%;
}

.line2 {
  position: absolute;
  border-top: 1px solid #006FB4;
  width: 108%;
  left: -4%;
  top: 75%;
}

.line3 {
  border-left: 1px solid #006FB4 !important;
  height: 218%;
  position: absolute;
  left: 28.3%;
  top: -79%;
}

.clinet-circle2 {
  position: relative;
  top: -422px;
  left: 747px;
}

#circle2 {
  width: 530.33px;
  height: 530.33px;
  border: 2px solid #006FB4;
  border-radius: 50%;
}

.about-h2 {
  margin: 180px 0px 0px 124px;
}

.clinet-para2 {
  margin: 30px 120px 0px 52px;
  text-align: right;
}

.card-row {
  margin: -263px 0px 0px 0px;
}

.cards {
  background-image: linear-gradient(180deg, rgba(0, 155, 64, 0.29) 0%, rgba(0, 111, 180, 0.29) 100%);
  border-radius: 46px;
  width: 100%;
  padding: 40px !important;
  margin: 40px;
  height: 350px;
}

.cards h3 {
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  padding: 20px 0px 20px 0px;
}

.border-line {
  content: '';
  background: #ffff;
  position: absolute;
  top: 29%;
  left: 38%;
  bottom: 30px;
  width: 167px;
  height: 8px;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

.cards:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.cards p {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
}

.heading-test {
  font-weight: 500;
  font-size: 54px;
  line-height: 70px;
  padding: 30px !important;
  transform: rotate(-90deg) translateX(-100%);
  transform-origin: left top;

  /*
      transform: rotate(-90deg) translateX(-100%);
    transform-origin: left top;
    font-weight: 500;
    font-size: 40px;
    line-height: 70px; */

}

.heading-line {
  position: absolute;
  width: 140px;
  border: 1px solid #000000;
  transform: rotate(-90deg);
  margin: 66px 0px 0px 47px !important;
}

.selectedClient {
  font-weight: 900;
  font-size: 22px;
  margin: -3px 0px 0px 0px;
  position: absolute;
  left: 37%;
  top: 18%;
  transform: rotate(-90deg) translateX(-100%);
  transform-origin: left top;
}

.seletline {
  border-left: 2px solid black;
  height: 105px;
  position: absolute;
  left: 42%;
  /* margin-left: -3px; */
  top: 75%;
}

/*.clinetlogo{
  margin: -362px -162px;
  width: 80%;
}
.selectedrow{
  margin: 40px 0px 300px 0px;
}*/
footer {
  margin-bottom: 0px !important;
}

@media screen and (max-width:768px) {
  body {
    overflow-x: hidden !important;
  }

  .cline-cont {
    display: flex;
    flex-direction: column;
  }

  .cards p {
    font-size: 14px;
    margin-top: 25px;
  }

  .seletline {
    border: none !important;
  }

  #circle2,
  #circle {
    height: 0px;
    border: none;
    /* width: 0px; */
  }

  .selectedClient {
    font-size: 26px;
    transform: none;
    left: 0;
    top: 0;
    margin-top: -70px;
    margin-left: 64px;
  }

  .clientServices {
    margin-top: 115px !important;
  }

  .about-h2 {
    margin: 174px 0 0 0;
    font-size: 26px;
  }

  .clinet-para2 {
    margin: 4px 120px 0px 0px;
    text-align: left;
  }

  .lines {
    left: 0%;
  }

  .lines2 {
    left: 21%;
    top: -24px;
  }


  .line3 {
    /* border: none ; */
    /* top: 0; */
    /* left: 0; */
    /* border-left: 0; */
    /* height: 0; */
    /* display: none; */
    border: none !important;
    height: 0;
    display: none !important;
    border-left: 0px !important;
    width: 0px;
  }

  .line {
    display: none !important;
    height: 0px !important;
  }

  .line2 {
    border: none !important;
    /* display: none; */
  }

  .clinet-circle2 {
    position: inherit;
    width: 0px;
  }

  .about-h1 {
    margin: 0 !important;
    font-size: 26px;
  }

  .clinet-circle {
    margin: 0;
  }

  .clinet-para {
    margin: 0;
    margin-bottom: 20px;
  }

  .cards {
    margin: 0;
  }

  .border-line {
    left: 33px;
  }

  .card-row {
    margin: 213px 0 0 0;
  }
}

@media screen and (max-width:600px) {
  body {
    overflow-x: hidden !important;
  }

  .selectedClient {
    transform: none;
    left: 0;
    top: 0;
    margin-top: -70px;
    margin-left: 20px;
  }

  .clientServices {
    margin-top: 115px;
  }

  .clinet-para2 {
    margin-right: 0;
  }

  .cards h3 {
    font-size: 17px;
  }

  .cards {
    margin-top: 30px;
  }

  .border-line {
    left: 85px;
    top: 124px;
  }

  #circle,
  #circle2 {
    width: 320px;
  }
}


@media screen and (max-width:1024px) {
  .clinet-circle2 {
    left: 538px;
  }

  .line {
    height: 170%;
  }
}


@media screen and (max-width:1440px) {
  body {
    overflow-x: hidden !important;
  }

  .line {
    height: 130%;
  }

  .selectedClient {
    font-size: 30px;
  }

  .seletline {
    height: 144px;
  }
}