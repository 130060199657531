html,
body {
   min-height: 100vh; 
  overflow-x: hidden !important;
  scroll-behavior: smooth;
}
li{
  list-style: none;
}

.logo {
  margin: 0px 0px 0px -66px !important;

}


#collasible-nav-dropdown {
  color: rgb(0, 0, 0);
  font-weight: bold;
}

.navheader {
  margin: 20px 0px 0px 180px;
}

.navlink {
  font-weight: 700;
  /* margin: 20px 0px 10px 0px !important; */
  color: #000000;
  padding: 0 10px;
  text-decoration: none;
  font-family: 'Montserrat';
}

.navlink:hover {
  text-decoration: none;
}


.navlink:hover {
  transition: 0.5s;
  border-radius: 4px;
  border-bottom: 5px solid #006FB4;
}

 .form-control 
  {
  background: linear-gradient(91deg, #009B40 10.33%, #006FB4 94.62%) !important;
  color: white !important;
  height: 40px;
}

.about-section{
  padding-top: 55px;
}

/*footer */
footer{
  background: #006FB4;
  color: #ffff;
  padding: 20px;
}

footer p{
  font-size: 12px;
  text-align: left;
}

.about-section h2{
 padding: 20px;
}
.about-section p{
  padding: 20px;
  font-size: 13px;
}
.box1{
background: #4DC17D;
color: #ffff;
width: 300px;
height: 228px;
padding: 10px;
font-size: 12px;
text-align: left;
}
.box1 ,.box2:hover{
  transform: scale(1.1);
}
.box2{
  background: #0E5A89;
  color: #ffff;
  width: 300px;
  height: 320px;
  padding: 10px;
  position: relative;
  left: 260px;
  top: -65px;
  font-size: 12px;
  }

  .busines-para{
    font-size: 14px;
    padding: 50px 30px 30px 30px;
    line-height: 1.8;
  }
  .testi{
    background: linear-gradient(103deg, rgba(0, 155, 64, 0.70) 2.26%, rgba(0, 111, 180, 0.70) 100%);
  }
  .testi h1{
    padding: 50px 0px 30px 0px;
    font-size: 35px;
  }

  .test-box{
    background: rgba(0, 44, 71, 0.62);
    color: #ffff;
    padding: 20px;
    height: 100%;
  }

  .test-box :hover {
    transform: scale(1.1);
  }
  
  .test-box  h4{
    font-size: 18px;
    padding: 30px 0px 10px 0px;
  }
.test-box h6{
  font-size: 14px;
  padding: 0px 0px 20px 0px;
}

.test-box p{
  font-size: 12px;
  padding: 15px;
}


.green-box{
  background: #016A3E;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
color: #ffff;
padding: 10px;
height: 180px;
margin-bottom: 20px;
}
.green-box:hover {
  transform: scale(1.1);
}
.blue-box:hover {
  transform: scale(1.1);
}

.green-box h5{
  font-size: 17px;
  padding: 10px;
}
.green-box p{
  font-size: 12px;
  padding: 8px;
}
.blue-box{
  background: #005B94;
box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
color: #ffff;
padding: 10px;
height: 180px;
margin-bottom: 20px;
}
.blue-box h5{
  padding: 10px;
  font-size: 17px;
}
.blue-box p{
  font-size: 12px;
  padding: 8px;
}

@media screen and (max-width:768px) {
  .logo {
    /* padding: 10px; */
    width: 100%;
    margin: 0px 0px 0px 0px !important;
  }
  .navheader {
    margin: 0;
  }
  .navlink {
    font-size: 12px;
    padding: 0px 5px;
  }

  .green-box h5 , .blue-box h5{
    font-size: 14px;
  }

  .green-box , .blue-box{
    height: 180px;
    padding: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .green-box p ,.blue-box p {
    font-size: 10px;
    align-items: center;
  }
}
@media screen and (min-device-width: 760px) and (max-device-width: 768px) { 
  .box1{
    width: 258px;
      height: 288px;
      padding: 10px;
      font-size: 12px;
      margin: 10px;
  }
  .box2{
    width: 258px;
    /* height: 288px; */
    padding: 10px;
    font-size: 11px;
    margin: 10px;
    position: relative;
    left: 88px;
    top: -71px;
  }
  .test-box h4 {
    font-size: 17px;
  }
  .test-box h6 {
    font-size: 10px;
  }
  
  .test-box {
    /* width : 210px; */
    /* height : 410px; */
    padding: 6px;
  }
  .finace-img{
    width: 100%;
    margin-top: 70px;
  }
}
@media screen and (max-width:600px) {
 
  .box2{
    position: static;
    padding: 0px;
    position: relative;
    margin: auto;
    /* width: 259px; */
    /* height: 256px; */
    left: 30px;
  }
   
/* testimonials responsvce */
#testimonials{
  margin: 120px 0px 30px 0px;
}
.testimonial-boxes-wrapper{
  width: 75% !important;
}
.test-box{
  padding: 10px;
}
.test-box h4{
  font-size: 18px;

  padding: 0px;
}
.test-box h6{
  font-size: 13px;

  padding: 0px;
}
.test-box p{
  font-size: 12px;

  padding: 4px;
}
  .box1{
    width: 280px;
    height: 256px;
  }
  .about-section p{
    font-size: 12px;
    text-align: left;
  }

  .about-section h2 {
    padding: 20px;
    font-size: 24px;
}
.finace-img{
  /* height: 300px; */
  /* margin-bottom: 20px; */
}
.busines-para {
  font-size: 13px;
  padding: 10px 0px 20px 0px;
}
.about-section h3{
  /* margin: 40px 0px 0px 0px; */
    font-size: 18px;
}
.busines-headg{
  font-size: 18px;
}
.testi h1 {
  font-size: 28px;
  padding: 15px 0px 0px 0px;
}
}

