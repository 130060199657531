#contact {
  width: 456px;
  height: 456px;
  border: 1px solid #006FB4;
  border-radius: 50%;
}

#contact2 {
  width: 444px;
  height: 444px;
  border: 1px solid #006FB4;
  border-radius: 50%;
}
.contact-section-container{
  padding: 0px 95px;
}
.contact-section-container h4{
 font-weight: 600;
 text-align: center;
}


.contact-line {
  border-left: 1px solid #006FB4 !important;
  height: 210%;
  position: absolute;
  left: 488px;
  top: -113px;
}

.contact-line2 {
  position: absolute;
  border-top: 1px solid #006FB4;
  width: 211%;
  left: -229px;
  top: 561px;
}

.contact-line3 {
  /* border-left: 1px solid #006FB4 !important; */
  height: 118%;
  position: absolute;
  left: 1238px;
  top: -113px;
}

.contact-line4 {
  position: absolute;
  border-top: 1px solid #006FB4;
  width: 220%;
  left: 146px;
  top: 459px;
}

.contact-line5 {
  border-left: 1px solid #006FB4 !important;
  height: 81%;
  position: absolute;
  left: 171px;
  top: 108px;
}



.video-top {
  position: absolute;
  top: 30%;
  width: 100%;
  left: 35px;
}

.ifrm {
  position: absolute;
  top: -688px;
  width: 100%;
}

Form.Group {
  background: #B5DED1 !important;
}

.input {
  background: #B5DED1 !important;

  padding: 30px !important;
}
.form-control{
  padding: 25px;
  height: 47px !important;
}
.form-control::placeholder{
  color: white;
}

textarea {
  background: #B5DED1 !important;
  height: 180px !important;

}

::placeholder {
  font-family: 'Montserrat';
  color: rgba(0, 0, 0, 0.4);
  font-weight: 500;
  font-size: 15px;
}

.contdrop {
  background-image: linear-gradient(180deg, rgba(0, 155, 64, 0.29) 0%, rgba(0, 111, 180, 0.29) 100%);
  margin: 19px 1px 1px 0px !important;
  height: 60px !important;
  padding: 25px !important;
}

.dropdown-text {
  margin: -17px 0px 0px 0px !important;
}

/*
.sucs {
  border-radius: 0px !important;
  font-size: 18px;
}*/

.content{
  margin-top: -400px;
  /* margin-bottom: 340px; */
}
::placeholder {
  color:white !important;
  opacity: 1; /* Firefox */
}

::-ms-input-placeholder { /* Edge 12-18 */
  color:white !important;
}


.sucessMsg {
  color: #6B8E23;
  margin-bottom: 10px;
}


@media screen and (min-width:1023px) {
  .contact-line2 {
    width: 100%;
  }

  /* .video-top { 
    left: 0;
    top: 16%;
  }*/
}

@media screen and (max-width:600px) {
  .d-block {
    width: 100%;
    height: 300px;
  }
  .video-top {
    top: 15%;
    left: 0;
  }
  .contact-section-container h3{
    font-size: 18px;
  }
 
}

@media screen and (max-width:768px) {
  body {
    overflow-x: hidden;
  }

  
  .form-control{
    padding: 20px;
  }
  .carousel {
    margin-top: 0 !important;
  }

  .video-top {
    position: absolute;
    top: 18%;
    width: 100%;
    left: 0px;
  }

  #contact,
  #contact2 {
    border: none;
    width: 0;
    height: 0;
  }

  .ifrm {
    top: 0;
  }

  .contact-line3,
  .contact-line2,
  .contact-line1,
  .contact-line {
    height: 0;
    top: 0;
    left: 0;
    width: 0;
  }

  .hedi-contact {
    /* margin: 0 !important; */
    margin: 558px 0px 0px 5px !important;
  }

  h1 {
    font-size: 35px;
  }

  .content {
    /* margin-top: 1050px; */
  }

  .contact-circle {
    margin: 0 !important;
  }
}

@media screen and (max-width:600px) {
  .hedi-contact {
    font-size: 26px;
    margin: 0 !important;
  }
  .contact-section-container{
    padding: 0px 55px;
  }

  .input {
    padding: 22px !important;
    height: 48px !important;
  }

  .contdrop {
    height: 49px !important;
  }

  .carousel-control-prev,
  .carousel-control-next {
    top: -55% !important;
  }

  .content {
    /* margin-top: 465px; */
    margin-top: 542px;
  }
}