body{
  overflow-y: hidden;
}

/* #home {
  width: 656px;
  height: 656px;
  border: 1px solid #006FB4;
  border-radius: 50%;
} */

.home-circle {
  position: relative;
  left: 7%;
  margin-top: 3%;
}

.happiness2 {
  font-size: 20px;
  font-weight: 900;
  height: 8vh;
  width: 200px;
  background-color: #EAA43A;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.happiness3 {
  font-size: 20px;
  font-weight: 900;
  height: 8vh;
  width: 200px;
  background-color: #3ABC0C;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.happinessScore {
  padding: 30px;
  margin-left: 30px;
}

.happinessValue {
  padding: 40px;
}

.happiness4 {
  font-size: 20px;
  font-weight: 900;
  height: 8vh;
  width: 200px;
  background-color: #009B40;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.happiness5 {
  font-size: 20px;
  font-weight: 900;
  height: 8vh;
  width: 200px;
  background-color: #4A24B5;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.happiness {
    font-size: 20px;
    font-weight: 900;
    height: 8vh;
    width: 200px;
    background: #EA453A;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.dotedimg {
  width: 100%;
  margin-left: 20px;
}

.OurExpert {
  /* margin-top: -375px; */
  margin-top: 75px;
}

.dottedGraph {
  position: relative;
  top: -730px;
}

.borders {
  content: '';
  background: linear-gradient(180deg, rgba(0, 155, 64, 0.7) 0%, rgba(0, 111, 180, 0.7) 100%);
  position: absolute;
  top: 75%;
  left: 6%;
  bottom: 30px;
  width: 107px;
  height: 8px;
  border-radius: 20px;
}

.borders2 {
  content: '';
  background-image: linear-gradient(180deg, #009B40 0%, #007A97 54.17%, #006FB4 100%);
  position: absolute;
  top: 75%;
  left: 15% !important;
  bottom: 30px;
  width: 52px;
  height: 8px;
  border-radius: 20px;
}
.deskt-img{
  width: 100%;
}

.vl1 {
  border-left: 1px solid #006FB4 !important;
  height: 266.5%;
  position: absolute;
  left: 22.2%;
  top: -100%;
}

.vl2 {
  position: absolute;
  border-top: 1px solid #006FB4;
  width: 100%;
  left: -8%;
  top: 69%;
}

.vl3 {
  border-left: 1px solid #006FB4 !important;
  height: 161.5%;
  position: relative;
  left: 189%;
  top: -305%;
}

.vl4 {
  border-right: 1px solid #006FB4;
  height: 185%;
  position: absolute;
  left: 85%;
  margin-left: -3px;
  top: 0;
}

.vr {
  height: 200px;
}

.vl5 {
  height: 0.2px;
  width: 90px;
  margin-top: -8%;
  position: absolute;
  left: 80.2%;
  height: 0.2px;
  width: 5.1%;
  background-color: #006FB4;
  /* left: 77.5%;*/
}

.vl6 {
  margin-top: 12.9% !important;
  width: 71%;
  /* top: 291.9%; */
  background-color: #006FB4;
  position: absolute;
  left: 29.1%;
}

.home-hed {
  font-family: 'Poppins';
  margin: 285px -40px 0px 0px !important;
  font-weight: 700;
  font-size: 54px;
  line-height: 70px;
  padding-left: 73px;
}

.home-para1 {
  margin: 36px 0px 0px 85px !important;
}

.about-sec {
  margin-top: 70px !important;
  padding-bottom: 30px;
  background-image: linear-gradient(180deg, rgba(0, 111, 180, 0.2) 0%, rgba(0, 155, 64, 0.2) 100%);
}

.about-seched {
  font-family: 'Poppins';
  margin: 0;
  position: absolute;
  left: 0;
  top: 0;
  -moz-transform: rotate(-90deg) translateX(-100%);
  transform: rotate(-90deg) translateX(-100%);
  -moz-transform-origin: left top;
  transform-origin: left top;
}

.about-secpar {
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
}

.ourexrt {
  padding: 150px 0px 0px 200px;
  position: relative;
}

.img-section {
  position: absolute;
  top: 27%;
}

.ourexprt {
  margin-left: -1px;
  position: relative;
}

.ourexdiv {
  position: absolute;
  top: 25%;
  left: 10%;
}

.hppneshedg {
  font-family: 'Poppins';
  margin-top: 205px;
  text-align: center;
  margin-bottom: 40px;
  display: flex;
  margin-left: 58px;
}

.borderdiv {
  border-bottom: 8px solid #006FB4;
  font-family: 'Poppins';
}

.clinets {
  margin: 100px 0px 160px 0px;
}

.clinetimg {
  position: relative;
  margin-left: 100px;
}

.clinetlog {
  position: absolute;
  top: 58%;
  left: 54%;
  display: none;
}

.clintshdg {
  position: absolute;
  text-align: end;
  margin: 20px 175px 30px 0px;
  font-family: 'Poppins';
}

.clintshdg p {
  margin: 30px 0px 0px 10px;
}

.clintborder {
  content: '';
  background-image: linear-gradient(180deg, #009B40 0%, #007A97 54.17%, #006FB4 100%);
  /* position: absolute;
  top: 52%;
  left: 30%; */
  /* bottom: 46%; */
  /* width: 234px; */
  height: 7px;
  border-radius: 20px;
}

input[type='range'] {
  width: 100%;
  height: 30px;
  overflow: hidden;
  cursor: pointer;
}
input[type='range']:hover {
  transform: scale(0.8);
  transition-duration: 0.5s;
}
input[type='range'],
input[type='range']::-webkit-slider-runnable-track,
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
}

input[type='range']::-webkit-slider-runnable-track {
  width: 200px;
  height: 10px;
  background: #AAA;
}

input[type='range']::-webkit-slider-thumb {
  position: relative;
  height: 30px;
  width: 30px;
  margin-top: -10px;
  background: linear-gradient(180deg, #009B40 0%, #007A97 54.17%, #006FB4 100%);
  border-radius: 50%;
  border: 2px solid white;
}

input[type='range']::-webkit-slider-thumb::before {
  position: absolute;
  content: '';
  height: 10px;
  /* equal to height of runnable track */
  width: 500px;
  /* make this bigger than the widest range input element */
  left: -502px;
  /* this should be -2px - width */
  top: 8px;
  /* don't change this */
  background: #777;
}
input[type='range']::-webkit-slider-thumb:hover{
  transform: scale(0.6);
  transition-duration: 0.5s;
}
.span2 {
  margin-top: -22px;
}

.cloumn {
  margin-left: -16px;
  background-color: #34DA34;
}


.services-bg {
  background-image: url();
}

.MSMELoan {
  position: relative;
  left: 241px;
  top: 59px;
}

.LoanRetails {
  position: relative;
  right: 15%;
  top: 55px;
}

.privateEquilaty {
  position: relative;
  left: 234px;
  top: 117px;
}

.startUpFunding {
  display: flex;
  flex-direction: column;
  margin-top: 22px;
}

.ourEquilaty {
  position: relative;
  left: 146px;
  top: 27px;
  font-family: 'Poppins';
}

.startUp {
  position: relative;
  left: -3%;
  top: 4px;
}
.img-effect{
  transition: transform .2s; /* Animation */
}

.img-effect:hover {
  transform: scale(1.3); 
}
.two-btons{
  margin-top: 130px;
}
@media screen and (max-width: 768px) {

  html,
  body {
    overflow-x: hidden;
    /* -webkit-text-size-adjust: none; */
  }

  .vl6 {
    border: none;
    width: 0;
  }

  .dottedGraph {
    top: -340px;
  }

  .service2 {
    margin-top: 0;
  }

  .service3 {
    margin-bottom: 0;
  }

  .MSMELoan {
    left: 37px !important;
    top: 20% !important;
  }

  .LoanRetails {
    right: 50px;
    top: -5px;
  }

  .privateEquilaty {
    top: 33px;
    left: -358px;
  }

  .ourEquilaty {
    left: 64%;
    top: -147px;
  }

  .ourEquilaty h1 {
    line-height: 35px;
    font-size: 29px;
  }

  .startUp {
    position: relative;
    top: -120px;
    left: 200px;
  }

  .OurExpert {
    margin-top: -290px;
  }

  .vl2,
  #home,
  .vl5,
  .vl4 {
    border: none;
    height: 0;

  }

  .ourexdiv {
    position: absolute;
    top: 25%;
    left: 11%;
  }

  .ourexrt {
    padding: 30px;
    margin-top: 50px;
  }

  .home-circle {
    margin-bottom: 300px !important;
    left: 0;
    margin-top: 0;
  }

  .about-seched {
    position: static;
    transform: none;
    margin-bottom: 30px;
    font-size: 30px;
  }

  .happinessScore {
    margin-left: 0;
    padding-left: 20px;
  }

  .borders {
    top: 189px;
    left: 33px;
    width: 160px;
  }

  footer {
    margin-top: 50px !important;
  }

  .borders2 {
    top: 190px;
    left: 210px !important;
  }

  .home-hed {
    margin: 50px 0 0 30px !important;
    padding-left: 0;
    font-size: 40px;
  }

  .home-para1 {
    margin: 20px 0 0 30px !important;
    font-size: 19px !important;
  }

  .vl1,
  .vl3 {
    border-left: none !important;
    left: 0;
    top: 0;
    height: 0;
  }

  .clinetimg {
    margin-left: 10px;
    width: 100%;
    margin-top: 87px;
  }

  .clintshdg {
    margin-top: 94px;
    margin-right: 50px;
  }

  .clinetlog {
    /* top: 71%; */
    /* left: 39%; */
    /* margin-bottom: 100px; */
    display: none;
  }

  .clinets {
    margin: 100px 0px 120px 0px;
  }

  .hppneshedg {
    margin-top: 46px;
  }

  .happiness,
  .happiness2,
  .happiness3,
  .happiness4,
  .happiness4 {
    margin-top: 10px;
  }

  .startUpFunding {
    margin-top: 4px;
  }
}

@media screen and (max-width:600px) {

  html,
  body {
    overflow-x: hidden;
    /* -webkit-text-size-adjust: none; */
  }

  .about-par{
    padding: 10px !important;
    margin: 0px !important;
}
  .img-exrts{
    height: 70% !important;
    width: 50% !important;
}
  .servicesmob {
    display: none !important;
  }

  h1 {
    font-size: 27px;
  }

  .dotedimg {
    display: none;
  }

  .happinessValue {
    padding-left: 10px;
    padding-right: 15px;
    margin-top: 24px;
  }

  .happinessScore {
    margin-left: 0;
    padding-right: 15px;
    padding-left: 5px;
  }

  .dottedGraph {
    top: 0px;
  }

  .MSMELoan {
    left: 1% !important;
  }

  .startUp,
  .privateEquilaty {
    top: -20px !important;
    left: 0 !important;
  }

  .LoanRetails {
    right: 20px !important;
  }

  .ourEquilaty {
    /* top: -30px !important; */
    /* left: 20% !important; */
    top: -41px !important;
    left: 20% !important;
  }

  .imgpreqt {
    margin: 43px 0px -55px -25px;
  }

  .span1 {
    margin: -27px 0px 90px 91px;
  }

  .prv {
    margin-left: -3px;
  }

  .OurExpert {
    margin-top: 0px;
  }

  #home {
    /* width: 320px; */
    /* margin-top: 50px; */
  }

  .home-hed {
    line-height: 45px;
    font-size: 26px;
    text-align: center;
    padding-bottom: 0px;
    font-weight: 900;
    margin: 0px !important;
    word-break: normal;
  }

  .home-circle {
    margin-left: 25px !important;
  }

  .vl,
  .v2 {
    margin: 0px 0px 0px 120px;
  }

  .home-para1 {
    font-size: 14px !important;
  }

  .clintshdg {
    font-size: 24px !important;
    margin: -54px 20px 0px 0px !important;
  }

  .clintshdg h1 {
    line-height: 46px;
    margin-top: 86px;
  }

  .clintshdg p {
    font-size: 14px;
  }

  .clinetlog {
    position: absolute;
    left: auto;
    margin: 151px 27px;
    width: 80%;
  }

  .clintborder {
    position: absolute;
    /* left: 44%; */
    width: 56%;
    top: 65%;
  }
  .clint-para{
    margin-left: 3px;
    margin-top: 42px;
  }

  .ourexdiv {
    margin-top: -113px;
  }

  .hppneshedg {
    margin: 30px;
    font-size: 30px;
  }

  .ourexdiv h1 {
    font-size: 25px;
    line-height: 42px;
  }

  .rowexpert {
    display: flex;
    flex-direction: column-reverse;
    padding-top: 80px;
  }

  .experbox {
    margin-top: 80px;
  }

  .clinetimg {
    margin-left: 0;
    margin-top: 261px;
    padding: 20px;
  }

  .clinets {
    display: flex;
    flex-direction: column-reverse;
    /* margin-bottom: 502px; */
  }

  .happiness,
  .happiness2,
  .happiness3,
  .happiness4,
  .happiness4 {
    margin-top: 10px;
  }
  .clinets{
    flex-direction: row-reverse;

  }
  .two-btons {
    margin-top: 40px;
}
}

/* range */

input[type=range] {
  height: 33px;
  /* background-image: linear-gradient(180deg, rgba(0, 111, 180, 0.2) 0%, rgba(0, 155, 64, 0.2) 100%) !important; */
  /* -webkit-appearance: none; */
  margin: 10px 0;
  width: 100%;
}

input[type=range]:focus {
  outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 16px;
  cursor: pointer;
  /* animate: 0.2s; */
  box-shadow: 0px 0px 0px #000000;
  background: #FFFFFF;
  border-radius: 25px;
  border: 0px solid #8A8A8A;
}

input[type=range]::-webkit-slider-thumb {
  box-shadow: 1px 1px 1px #828282;
  border: 0px solid #8A8A8A;
  height: 26px;
  width: 27px;
  border-radius: 44px;
  background: linear-gradient(180deg, #006FB4 0%, #009B40 100%);
  /* background: #34DA34; */
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -5px;
}

input[type=range]:focus::-webkit-slider-runnable-track {
  background: #FFFFFF;
}

input[type=range]::-moz-range-track {
  width: 100%;
  height: 16px;
  cursor: pointer;
  /* animate: 0.2s; */
  box-shadow: 0px 0px 0px #000000;
  background: #FFFFFF;
  border-radius: 25px;
  border: 0px solid #8A8A8A;
}

input[type=range]::-moz-range-thumb {
  box-shadow: 1px 1px 1px #828282;
  border: 0px solid #8A8A8A;
  height: 26px;
  width: 27px;
  border-radius: 44px;
  background: #34DA34;
  cursor: pointer;
}

input[type=range]::-ms-track {
  width: 100%;
  height: 16px;
  cursor: pointer;
  /* animate: 0.2s; */
  background: transparent;
  border-color: transparent;
  color: transparent;
}

input[type=range]::-ms-fill-lower {
  background: #FFFFFF;
  border: 0px solid #8A8A8A;
  border-radius: 50px;
  box-shadow: 0px 0px 0px #000000;
}

input[type=range]::-ms-fill-upper {
  background: #FFFFFF;
  border: 0px solid #8A8A8A;
  border-radius: 50px;
  box-shadow: 0px 0px 0px #000000;
}

input[type=range]::-ms-thumb {
  margin-top: 1px;
  box-shadow: 1px 1px 1px #828282;
  border: 0px solid #8A8A8A;
  height: 26px;
  width: 27px;
  border-radius: 44px;
  background: #34DA34;
  cursor: pointer;
}

input[type=range]:focus::-ms-fill-lower {
  background: #FFFFFF;
}

input[type=range]:focus::-ms-fill-upper {
  background: #FFFFFF;
}

.sapn2 {
  margin-top: -20px !important;
}

@media screen and (max-width:1024px) {

  body,
  html {
    overflow-x: hidden !important;
  }

  .vl6 {
    margin-top: 13.9% !important;
  }

  /*.LoanRetails {
  right: 10% !important;
  top: 277px !important;
}
.startUp {
  left: -2% !important;
  top: 173px !important;
}
.privateEquilaty {
  left: 105px !important;
  top: 215px !important;
}
.MSMELoan {
  left: 116px !important;
  top: 295px !important;
}
.ourEquilaty {
  left: 121px !important;
  top: 117px !important;
} */
  .about-par {
    font-size: 15px;
    margin: 0px 0px 0px 40px ;
  }

  .vl4 {
    border: none;
  }

  .borders {
    left: 11%;
  }

  .borders2 {
    left: 23% !important;
  }

  .happiness,
  .happiness2,
  .happiness3,
  .happiness4,
  .happiness5 {
    /* margin-top: -48px; */
    margin-top: -33px;
  }

  .urgent {
    width: 129%;
  }
  .clinetimg {
    position: relative;
    margin-left: -21px;
}
}


@media screen and (max-width:1440px) {
 .about-par {
      padding: 0px 73px 0px 42px ;
  }
}
