/* #blog {
    width: 600px;
    height: 600px;
    border: 1px solid #006FB4;
    border-radius: 50%;
    position: relative;
    top: 38px;
    left: -158px;

} */

/* #blog2 {
    width: 500.33px;
    height: 500.33px;
    border: 1px solid #006FB4;
    border-radius: 50%;
    position: absolute;
    top: 46%;
    right: 14px;
} */

.blg-h1 {
    /* position: absolute; */
    top: 25%;
    left: 43%;
}

/* .vert1 {
    border-left: 1px solid #006FB4 !important;
    height: 49%;
    position: absolute;
    left: 56%;
    top: -27%;
} */

/* .vert2 {
    position: absolute;
    border-top: 1px solid #006FB4;
    width: 52%;
    left: -166px;
    top: 51%;
} */

.Blogs {
    position: relative;
    top: -10px;
}
/*
.vert3 {
    border-left: 1px solid #006FB4 !important;
    height: 40%;
    position: absolute;
    left: 56%;
    top: 70%;
} */

footer {
    margin-top: 0 !important;

}

.vert4 {

    border-left: 1px solid #006FB4;
    height: 247%;
    position: absolute;
    left: 27%;
    margin-left: -75px;
    top: -116%;
}
/* 
.vert5 {
    position: absolute;
    border-top: 1px solid #006FB4;
    width: 193%;
    left: 77%;
    top: 51%;
} */

.testi {
    margin-top: -230px;
    margin-bottom: 80px;
}

.testhed {
    /* margin: -340px 0px -22px 0px !important; */
    margin: -485px 0px 0px 0px !important;
}
.card {
    width: 350px;
}

.card-text {
    color: #006FB4;
    font-weight: 700;
    text-decoration: none !important;
}

.viede1 {
    height: 500px;
}

.viede2 {
    height: 500px;
}

@media screen and (max-width: 768px) {

    #blog,
    #blog2,
    .vert1,
    .vert2,
    .vert3,
    .vert4,
    .vert5 {
        border: none;
        height: 0;
    }

    .card {
        width: 225px;
    }

    .Blogs {
        top: 0;
    }

    .carousel {
        margin-top: 380px;
    }

    .card2 {
        margin: 525px 0px 0px 0px;
    }

    .card3 {
        margin: 0px 160px 0px 0px;
    }

    .viede1 {
        height: 300px;
    }

    .viede2 {
        height: 300px;
    }

    .testhed {
        margin: 11px 0px 0px 0px !important;
    }

}

@media screen and (max-width: 600px) {
    .carousel-inner {
        display: flex;
    }

    .carousel-control-prev {
        left: auto;
        right: 40px;
        top: 75px;
    }

    .card {
        width: 290px;
    }

    .carousel-control-next {
        right: 0;
        top: 74px;
    }


    .viede1 {
        height: 300px;
    }

    .viede2 {
        height: 300px;
    }
}

@media screen and (min-width: 1024px) {
    .card {
        width: 90%;
    }

    .vert4 {
        left: 76%;
    }
}


@media screen and (max-width: 1440px) {
    .vert4 {
        display: none;
        border: none !important;
    }
}