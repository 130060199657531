.menu {
    z-index: 999;
    position: fixed;
    padding: 0;
    margin: 0;
    list-style-type: none;
}
.menu .share i.fa {
    height: 40px;
    width: 40px;
    text-align: center;
    line-height: 40px;
    background-color:#009B40;
    color: #212121;
    border-radius: 2px;
}
.menu .share:hover.bottom .submenu li:nth-child(1) {
    opacity: 1;
    top: 50px;
    transform: rotate(0deg);
    border-top: 1px dashed #ccc;
    transition-delay: 0.08s;
}
.menu .share:hover.bottom .submenu li:nth-child(2) {
    opacity: 1;
    top: 100px;
    transform: rotate(0deg);
    border-top: 1px dashed #ccc;
    transition-delay: 0.16s;
}
.menu .share:hover.bottom .submenu li:nth-child(3) {
    opacity: 1;
    top: 150px;
    transform: rotate(0deg);
    border-top: 1px dashed #ccc;
    transition-delay: 0.24s;
}
.menu .share:hover.bottom .submenu li:nth-child(4) {
    opacity: 1;
    top: 200px;
    transform: rotate(0deg);
    border-top: 1px dashed #ccc;
    transition-delay: 0.32s;
}
.menu .share:hover.bottom .submenu li:nth-child(5) {
    opacity: 1;
    top: 250px;
    transform: rotate(0deg);
    border-top: 1px dashed #ccc;
    transition-delay: 0.4s;
}
.menu .share:hover.left .submenu li:nth-child(1) {
    opacity: 1;
    left: -51px;
    transform: rotate(0deg);
    transition-delay: 0.5s;
    border-right: 1px dashed #ccc;
}
.menu .share:hover.left .submenu li:nth-child(2) {
    opacity: 1;
    left: -102px;
    transform: rotate(0deg);
    transition-delay: 1s;
    border-right: 1px dashed #ccc;
}
.menu .share:hover.left .submenu li:nth-child(3) {
    opacity: 1;
    left: -153px;
    transform: rotate(0deg);
    transition-delay: 1.5s;
    border-right: 1px dashed #ccc;
}
.menu .share:hover.left .submenu li:nth-child(4) {
    opacity: 1;
    left: -204px;
    transform: rotate(0deg);
    transition-delay: 2s;
    border-right: 1px dashed #ccc;
}
.menu .share:hover.left .submenu li:nth-child(5) {
    opacity: 1;
    left: -255px;
    transform: rotate(0deg);
    transition-delay: 2.5s;
    border-right: 1px dashed #ccc;
}
.menu .share:hover.right .submenu li:nth-child(1) {
    opacity: 1;
    left: 50px;
    transform: rotate(0deg);
    transition-delay: 0.08s;
    border-left: 1px dashed #ccc;
}
.menu .share:hover.right .submenu li:nth-child(2) {
    opacity: 1;
    left: 100px;
    transform: rotate(0deg);
    transition-delay: 0.16s;
    border-left: 1px dashed #ccc;
}
.menu .share:hover.right .submenu li:nth-child(3) {
    opacity: 1;
    left: 150px;
    transform: rotate(0deg);
    transition-delay: 0.24s;
    border-left: 1px dashed #ccc;
}
.menu .share:hover.right .submenu li:nth-child(4) {
    opacity: 1;
    left: 200px;
    transform: rotate(0deg);
    transition-delay: 0.32s;
    border-left: 1px dashed #ccc;
}
.menu .share:hover.right .submenu li:nth-child(5) {
    opacity: 1;
    left: 250px;
    transform: rotate(0deg);
    transition-delay: 0.4s;
    border-left: 1px dashed #ccc;
}
.menu .share:hover.top .submenu li:nth-child(1) {
    opacity: 1;
    top: -51px;
    transition-delay: 0.08s;
    transform: rotateY(0deg);
    border-bottom: 1px dashed #d9d9d9;
}
.menu .share:hover.top .submenu li:nth-child(2) {
    opacity: 1;
    top: -102px;
    transition-delay: 0.16s;
    transform: rotateY(0deg);
    border-bottom: 1px dashed #d9d9d9;
}
.menu .share:hover.top .submenu li:nth-child(3) {
    opacity: 1;
    top: -153px;
    transition-delay: 0.24s;
    transform: rotateY(0deg);
    border-bottom: 1px dashed #d9d9d9;
}
.menu .share:hover.top .submenu li:nth-child(4) {
    opacity: 1;
    top: -204px;
    transition-delay: 0.32s;
    transform: rotateY(0deg);
    border-bottom: 1px dashed #d9d9d9;
}
.menu .share:hover.top .submenu li:nth-child(5) {
    opacity: 1;
    top: -255px;
    transition-delay: 0.4s;
    transform: rotateY(0deg);
    border-bottom: 1px dashed #d9d9d9;
}
.menu .submenu {
    list-style-type: none;
    padding: 0;
    margin: 0;
}
.menu .submenu li {
    transition: all ease-in-out 0.5s;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
}
.menu .submenu li a {
    color: #212121;
}
.menu .submenu li a:hover i.fa {
    color: #fff;
}
.menu .submenu li a.facebook i.fa {
    background-color:#fff;
    color: #3b5999;
}

.menu .submenu li a:hover.facebook i.fa {
    background-color: #3b5999;
    color: #fff;
}
.menu .submenu li a.twitter i.fa {
    background-color:  #fff;
    color:#25D366 ;
}
.menu .submenu li a:hover.twitter i.fa {
    background-color: #25D366;
    color: #fff;
}
.menu .submenu li a.googlePlus i.fa {
    background-color: #fff;
    color:#0A66C2 ;
}
.menu .submenu li a:hover.googlePlus i.fa {
    background-color:#0A66C2;
    color: #fff;
}
.menu .submenu li a.instagram i.fa {
    background-color:#fff;
    color: #bc2a8d;
}
.menu .submenu li a:hover.instagram i.fa {
    background-color:  #bc2a8d;
    color: #fff;
}
.menu .submenu li:nth-child(1) {
    transform: rotateX(45deg);
}
.menu .submenu li:nth-child(2) {
    transform: rotateX(90deg);
}
.menu .submenu li:nth-child(3) {
    transform: rotateX(135deg);
}
.menu .submenu li:nth-child(4) {
    transform: rotateX(180deg);
}
.menu.topLeft {
    top: 280px;
    left: 15px;
}
.menu.topRight {
    top: 10px;
    right: 10px;
}
.menu.bottomLeft {
    bottom: 10px;
    left: 10px;
}
.menu.bottomRight {
    bottom: 10px;
    right: 10px;
}




.social{
    position: fixed;
    top:50%;
}
.social ul{
 list-style: none;
 padding: 0;
 transform: translateX(-270px);
}
.social ul li{
    display: block;
    margin: 10px ;
    background: rgba(0, 0, 0, 0.5);
    width: 300px;
    text-align: right;
    padding: 12px ;
    border-radius: 0 30px 30px 0;
    transition: all 1s;
}
.social ul li:hover{
    transform: translateX(110px);
}
.social ul li#twitter:hover{
    background-color: #25D366;
}
.social ul li#facebook:hover{
    background-color: #3A559F;
}
.social ul li#instagram:hover{
    background-color: #C11A76;
}
.social ul li#linkdin:hover{
    background-color: #0A66C2;
}
.social ul li a{
    color: #fff;
    text-decoration: none;
}
.social ul li i{
    text-align: center;
    color: #000;
    margin-left: 14px;
    background: #fff;
    padding: 2px;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    font-size: 18px;
    transform: rotate(0deg);
}
.social ul li:hover i{
    transform: rotate(360deg);
    transition: all 1s;
}