
.about-bar{
  border-radius: 6px;
  background-image: linear-gradient(180deg, #009B40 0%, #007A97 54.17%, #006FB4 100%); 
  width: 104px;
height: 8px;
opacity: 1;
}
.about-bar2{
  border-radius: 6px;
  /* background-color: #006FB4; */
   background-image: linear-gradient(180deg, #009B40 0%, #007A97 54.17%, #006FB4 100%); 
width: 40px;
height: 8px;
position: relative;
top: -24px;
left: 120px;
opacity: 1;
}
.vertical-lr {
  /* writing-mode: vertical-lr; */
}

.rotated {
  /* transform: rotate(180deg); */
}
.abot-h1{
  font-size: 28px;
}
.about-hed {
  margin: 130px 0px 0px 150px !important;
}
.abt-par1{
  line-height: 2.0;
  font-size: 14px;
  padding: 20px 95px 20px 0px;
}

.about-para1 {
  margin: 10px 120px 0px 152px !important;
}

.border {
  content: '';
   /* background-image: linear-gradient(180deg, #009B40 0%, #007A97 54.17%, #006FB4 100%); ; */
   /* background-image: linear-gradient(180deg, #009B6F 0%, #007A97 54.17%, #006FB4 100%); */
   background-image: linear-gradient(
  180deg,
  hsl(145deg 100% 30%) 0%,
  hsl(154deg 100% 30%) 11%,
  hsl(164deg 100% 30%) 23%,
  hsl(173deg 100% 30%) 35%,
  hsl(182deg 100% 30%) 46%,
  hsl(192deg 100% 30%) 56%,
  hsl(194deg 100% 31%) 66%,
  hsl(196deg 100% 32%) 75%,
  hsl(198deg 100% 33%) 84%,
  hsl(201deg 100% 34%) 92%,
  hsl(203deg 100% 35%) 100%
);
  position: absolute;
  top: 609px;
  left: 233px;
  bottom: 30px;
  width: 107px;
  height: 8px;
  border-radius: 20px;
}

.border2 {
  content: '';
  background-color: #006FB4;
  /* background-image: linear-gradient(180deg, #009B40 0%, #007A97 54.17%, #006FB4 100%); */
  position: absolute;
  top: 609px;
  left: 367px !important;
  bottom: 30px;
  width: 52px;
  height: 8px;
  border-radius: 20px;
}

/* .vertical {
  border-left: 1px solid #006FB4 !important;
  height: 32%;
  position: absolute;
  left: 56%;
  top: -11%;
}

.vertical2 {
  position: absolute;
  border-top: 1px solid #006FB4;
  width: 25%;
  left: -1px;
  top: 51%;
} */

/* .vertical3 {
  border-left: 1px solid #006FB4 !important;
  height: 47%;
  position: absolute;
  left: 29%;
  top: 62%;
} */

.about-hed2 {
  margin: 250px 0px 0px 178px !important;
}

.about-para2 {
  margin: 15px 0px 0px 178px !important;

}
/* 
.vertical4 {
  border-left: 1px solid #006FB4 !important;
  height: 122%;
  position: absolute;
  left: 54%;
  top: -11%;
}

.vertical5 {
  position: absolute;
  border-top: 1px solid #006FB4;
  width: 108%;
  left: -45px;
  top: 545px;
}

.vertical6 {
  position: absolute;
  border-top: 1px solid #006FB4;
  width: 23%;
  left: 31%;
  top: 111%;
} */

.about-hed3 {
  margin: 269px 0px 0px 58px !important;
}

.about-para3 {
  margin: 14px 412px 0px -188px !important;
}

.about-hed4 {
 margin: 60px 65px 20px 0px !important;
  font-size: 29px;
}

.about-row {
  margin-top: 0 !important;
}

.values-row {
  margin: 50px 0px 40px -74px !important;
}

.values {
  padding: 20px !important;
}

.about-icon {
  width: 89px;
  height: 89px;
}

.about-icon:hover {
  transform: scale(1.3);
  /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.icon-name {
  font-size: 15px;
  line-height: 18px;
  font-weight: 500;
  margin: 20px 0px 20px 10px !important;
}



.about-img {
  height: 300px;
  width: 300px;
  border-radius: 50%;
  /* border-radius: 50px; */
}

/* .name { */
/* float: right !important; */
/* margin: 20px 0px 20px 0px !important; */
/* } */
.name {
  margin-top: 14px;
}

.name-bar {
  content: '';
  background: #006FB4;
  bottom: 30px;
  width: 55%;
  height: 4px;
  border-radius: 20px;
  margin-left: 3px;
  background-image: linear-gradient(180deg, #009B40 0%, #007A97 54.17%, #006FB4 100%); 
opacity: 1;
}
.name-bar2 {
  content: '';
  background: #006FB4;
  bottom: 30px;
  width: 95%;
  height: 4px;
  border-radius: 20px;
  margin-left: 3px;
  background-image: linear-gradient(180deg, #009B40 0%, #007A97 54.17%, #006FB4 100%); 
opacity: 1;
}

.about-desc {
  /* margin: 47px 0px 30px 353px !important; */
  font-size: 14px;
}

.team-heading {
  margin-left: 187px;
}
.about-section{
  margin-top: -70px;
}
.about-v1 {
  border-top: 3px solid #000;
  opacity: 1.5;
  margin: -21px 22px 10px 285px;
  width: 63%;
}
/* .rota-hedg{
  margin-top: 280px;
} */
.about-hed5{
  font-size: 30px;
}

@media screen and (max-width:600px) {
  body {
    overflow-x: hidden;
  }

  .team-heading {
    margin-left: 0;
    margin-top: -60px;
  }
  .about-hed3 {
    margin-top: 0;
  }
  .about-hed {
    margin: 0px !important;
  }

  .about-para1 {
    margin: 10px 376px 30px -27px !important;
    /* padding: 20px; */
    font-size: 14px;
  }

  .about-section {
    margin-top: -105px !important;
  }

  .border {
    top: 555px;
    left: 74px;
  }

  .border2 {
    top: 555px;
    left: 211px !important;
  }

  .about-hed2 {
    margin: 95px 0px 20px 0px !important;
  }

  .about-para2 {
    margin: 0px 415px 30px 0px !important;
    font-size: 14px;
  }

  .col-about {
    margin-top: 385px;
    /* height: 400px; */
  }

  .about-hed3 {
    margin: -335px 0px 0px 0px !important;
    padding: 10px 0px 10px 0px;
  }

  .about-para3 {
    /* text-align: end;
    padding: 10px 20px 0px 48px; */
    font-size: 14px;
  }

  .about-hed4 {
    margin: 40px auto !important;
  }

  .values-row {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    /* margin: 22px 0px 50px 89px !important; */
  }

  /* 
  .bloks {
    display: flex;
    flex-direction: column;
  } */

  .about-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100% !important;
  }


  .name-bar {
    left: 21px;
    top: 424px;
    text-align: center;

  }

  .about-desc {
    word-break: normal;
  }

  .values-row {
    margin: -44px 0px 40px 0px;
  }

 

  .about-para1 {
    margin: 0 !important;
  }
  .rota-hedg{
    text-align: center;
    /* margin-left: 32px; */
    margin-top: 0px;
    margin-bottom: 20px;
}
  .about-v1 {
    height: 59px;
    display: none;
  }
 
  .swap-on-mobile {
		display: flex !important;
		flex-direction: column-reverse;
    margin-bottom: 40px;
	}

}


@media screen and (max-width:768px) {

  .abt-par1 {
    font-size: 12px;
    padding-right: 10px;
}
  .border {
    border: none !important;
  }

  /* .col-about {
    height: 0;
  } */

  .containr-about {
    margin-bottom: 100px !important;
  }

  .about-section {
    margin-top: -30px;
  }

  .col-about {
    background-image: none;
  }



  .about-img {
    width: 68%;
  }

  .about-para1,
  .about-para2,
  .about-para3 {
    font-size: 14px;
  }

  .about-hed,
  .about-hed3,
  .about-hed2 {
    font-size: 28px;
  }

  .about-hed4,
  .about-hed5 {
    font-size: 30px;
    margin: 0px !important; 
  }

  .values-row {
    margin:-14px 0px 40px 0px !important;
  }
  .rota-hedg {
    margin-top: 89px;
}
.about-v1{
  margin: -21px 22px 10px 177px;
    width: 29%;
}
}

@media screen and (max-width:1024px) {
  .about-para1 {
    font-size: 14px;
    margin: 1px 120px 0px 152px;
  }


  .about-hed3 {
    margin: 251px 0px 0px 26px;
  }
}


/* outlines of box*/
.outline{
  border: 1px solid   #009B40;
  padding: 10px 20px 0px 20px;
  height: 275px;
  border-radius: 30px 0px 40px 0px;
}

.outline h1 {
     padding-top: 10px;
}

.outline:hover{
  background-color:#007A97;
  color: #fff;
}
