.snip *,
      .snip *:before,
      .snip *:after {
        box-sizing: border-box;
        border-radius: 20px;
        transition: all 0.45s ease;
      }

      .snip {
        /* border: 1px solid #006FB4; */
        box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.5);
        position: relative;
        overflow: hidden;
        width: 100%;
        max-width: 270px;
        min-width: 230px;
        font-family: "Source Sans Pro", sans-serif;
        color: #fff;
        font-size: 17px;
        font-weight: 550;
        margin: 10px;
        text-align: left;
        transform: translateZ(0);
        border-radius: 20px;
      }

      .snip:hover .snip__title,
      .snip:hover .snip__text {
        transform: translateY(0);
        opacity: 0.9;
        transition-delay: 0.2s;
      }

      .snip::before,
      .snip::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #09a914;
        opacity: 0.5;
        transition: all 0.45s ease;
      }

      .snip::before {
        transform: skew(30deg) translateX(-80%);
      }

      .snip::after {
        transform: skew(-30deg) translateX(-70%);
      }

      .snip:hover::before {
        transform: skew(30deg) translateX(-20%);
        transition-delay: 0.05s;
      }

      .snip:hover::after {
        transform: skew(-30deg) translateX(-10%);
      }

      .snip:hover .snip__figcaption::before {
        transform: skew(30deg) translateX(-40%);
        transition-delay: 0.15s;
      }

      .snip:hover .snip__figcaption::after {
        transform: skew(-30deg) translateX(-30%);
        transition-delay: 0.1s;
      }

      .snip__image {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        backface-visibility: hidden;
        max-width: 100%;
        vertical-align: top;
      }

      .snip__figcaption {
        position: absolute;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        z-index: 1;
        bottom: 0;
        padding: 25px 15% 25px 20px;
      }

      .snip__figcaption::before,
      .snip__figcaption::after {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-image: linear-gradient(
          180deg,
          #009b15 0%,
          #003c97 54.17%,
          #006fb4 100%
          /* #006fb4 0%,
          #009b15 54.17%,
          #01470a 100% */
        );
        /* background-color:#084063 ; */
        box-shadow: 0 0 20px #006fb4;
        content: "";
        opacity: 0.5;
        z-index: -1;
      }

      .snip__figcaption::before {
        transform: skew(30deg) translateX(-100%);
      }

      .snip__figcaption::after {
        transform: skew(-30deg) translateX(-90%);
      }

      .snip__title,
      .snip__text {
        font-weight: 800;
        margin: 0;
        opacity: 0;
        letter-spacing: 1px;
      }

      .snip__title {
        /* font-family: "Teko", sans-serif; */
        margin-bottom: 5px;
        font-size: 21px;
        font-weight: 700;
        line-height: 1em;
        text-transform: uppercase;
      }

      .snip__text {
        font-weight: 520;
        line-height: 1.3em;
        font-size: 0.85em;
      }

      .snip__link {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
      }
      .snip-container {
        height: 380px;
        background-color: white;
        /* background-color:#009B40 ; */

        /* width: 1000px; */
      }
      .title {
        /* margin: 100px; */
        /* color: #0c0268; */
        color: #04598e;
        /* text-shadow: 1px 1px 2px #333; */
        font-size: 25px;
        font-weight: 700;
        text-transform: uppercase;
        padding-left: 100px;
        padding-right: 10px;
        z-index: 100;
      }

      .snip :hover {
      }
      .snip:hover .title {
        display: none;
      }
      .intro {
        font-size: 17px;
      }
      @media (max-width:768px) {
        .row{
            margin:0px !important;
            padding:0px !important;
        }
      }
    