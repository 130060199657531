.about__container {
  row-gap: 2rem;
  text-align: center;
}
.about__subtitle {
  margin-bottom: var(--mb-2);
}
.about__img {
  justify-self: center;
}
.about__img img {
  margin: auto;
  width: 200px;
  border-radius: 0.5rem;
}
.finace-img{
  width: 100%;
  margin: 0;
  height: 390px;
}
@media screen and (min-width: 768px) {
  .about__container {
    grid-template-columns: repeat(2, 1fr) !important;
    align-items: center;
    text-align: initial;
  }
  
  .about__img img {
    width: 300px;
  }
}
@media screen and (max-width: 568px) {

.finance-img-wrapper{
  display: flex;
  justify-content: center;
}

}
