.servis-h1{
  font-size: 35px;
  margin:  30px 0px 0px 120px;
}
.imghloan{
  height: 250px;
  width: 230px;
}

@media screen and (max-width:768px) {


}

@media screen and (max-width:600px) {

  html,
  body {
    overflow-x: hidden;
  }
  .servis-h1 {
    font-size: 30px;
    margin: 0;
  }
 
 
}

@media screen and (max-width:1024px) {
  body,html{
    overflow-x: hidden !important;
  }
 
}


@media screen and (max-width:1440px) {
  body,html{
    overflow-x: hidden !important;
  }
 
}