.home-section {
  min-height: 100vh;
  width: 100%;
  position: relative;
  background: url("../../images/image\ 9.png"), lightgray 50% / cover no-repeat;
  background-repeat: no-repeat;
  background-size: cover;
}
.bottom-blocks {
  position: absolute;
  bottom: -52px;
  right: 0px;
  height: 140px;
  left: 0px;

  z-index: 1000000;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 40px;
}
.bottom-blocks div {
  background-color: white;
  flex: 1;
  height: 100%;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.179);
}

.home-blocks-head {
  color: green;
  font-size: 17px;
}
.home-blocks-head :hover {
  color: #0E5A89;;
}
.home-block {
  display: flex;
  align-items: center;
  justify-content: center;
}
.first-block {
  border-top-right-radius: 70%;
}
.fifth-block {
  border-top-left-radius: 70%;
}

.gradient-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    103deg,
    rgba(0, 155, 64, 0.6) 2.26%,
    rgba(0, 111, 180, 0.6) 100%
  );
}
.head-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 50%;
  display: flex;
  align-items: center;
}
.home-head{
  /* z-index: 1; */
  color: #fff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  /* font-family: Inter; */
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 0px 0px 0px 102px;
}
/*whats app button */
.copyright{
  background: #019356;
  padding: 10px 0px 4px 0px;
  color: #fff;
}

@media only screen and (max-width: 1024px) {
  .bottom-blocks {
    height: 150px;
    gap: 30px;
  }

  .head-wrapper h2 {
    font-size: 49px;
    padding: 0px 0px 0px 80px;
  }
}

@media only screen and (max-width: 768px) {
  .bottom-blocks {
    height: 108px;
    bottom: -38px;
    gap: 13px;
    /* display: none; */
  }
  .home-blocks-head {
    font-size: 14px;
  }
  .head-wrapper h2 {
    font-size: 35px;
    padding: 0px 0px 0px 80px;
  }


}
@media only screen and (max-width: 450px) {
  .bottom-blocks {
    bottom: -15px;
    right: 0px;
    left: 0px;
    height: 50px;
    gap: 6px;
     display: none; 
  }
  .home-head{
    text-align: center;
 padding: 10px !important;
  }
  .home-blocks-head {
    font-size: 9px;
  }
  .head-wrapper {
    width: 100%;
  }
  .head-wrapper h2 {
    font-size: 35px;
    padding: 0px 0px 0px 35px;
  }
  .copyright{
    font-size: 14px;
  }
}
