.blog-section {
  min-height: max-content;
  padding: 40px 0px 55px 0px;
}
.blog-head {
  font-size: 35px;
  font-weight: 600;
  letter-spacing: 1px;
  padding-left: 100px;
  margin: 0px 0px 25px 0px;
  color: rgb(0, 0, 0);
}
.blog-para {
  margin-bottom: 55px;
  color: white;
  font-weight: 500;
}
.blog-slider-wrapper {
  height: max-content;
  width: 90vw;
  margin: auto;
}
.blog-card-img-wrapper img{
  height: 100%;
  width: 100%;
  object-fit:fill;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

/* blog card */

.blog-card {
  width: 331px;
  height: 451px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  background-color: white;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.29);
}
.blog-card-img-wrapper {
  height: 55%;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.blog-card-detail {
  height: 45%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  align-items: center;
  padding: 10px 30px;
}
.blog-card-detail h3 {
  font-size: 22px;
}
.blog-card-detail h3,
p {
  text-align: center;
  font-weight: 500;
}
.slick-slide > div {
  margin: 0px 28px;
}
.slick-track {
  padding-bottom: 40px;
}
.blog-section .slick-arrow::before {
  color: black;
}

.text-blue{
  color: #006FB4 !important;
}
a{
  text-decoration: none !important;
}
a:hover {
  text-decoration: none !important;
}
@media only screen and (max-width: 1024px) {
  .blog-card {
    width: 256px;
    height: 364px;

    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.29);
  }
  .blog-head {
    padding-left: 80px;
  }
}
@media only screen and (max-width: 800px) {
  .blog-head {
    padding-left: 70px;
  }
}

@media only screen and (max-width: 500px) {
  .slick-slide > div {
    margin: 0px 63px;
  }
  .blog-head {
    padding-left: 0px;
    text-align: center;
    font-size: 28px;
  }

  .blog-card {
    width: 218px;
    height: 329px;
    margin: auto;
  }

  .card-para {
    font-size: 13px;
  }
}
@media only screen and (max-width: 400px) {
  .slick-slide > div {
    margin: 0px 50px;
  }
}
@media only screen and (max-width: 350px) {
  .slick-slide > div {
    margin: 0px 17px;
  }
}
