.services-section {
  min-height: max-content;
  background: linear-gradient(
    103deg,
    rgba(0, 155, 64, 0.8) 2.26%,
    rgba(0, 111, 180, 0.8) 100%
  );
  padding: 40px 0px 55px 0px;
}
.services-head {
  font-size: 35px;
  font-weight: 600;
  letter-spacing: 1px;
  text-align: center;
  margin: 0px 0px 25px 0px;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
.services-para {
  margin-bottom: 55px;
  color: white;
  font-weight: 500;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}
.services-slider-wrapper {
  height: max-content;
  width: 90vw;
  margin: auto;
}

/* service card */

.services-card {
  width: 331px;
  height: 451px;
  padding: 0px;
  border-radius: 40px;
  background-color: white;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.29);
}
.service-card-img-wrapper {
  height: 45%;

  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
}
.service-card-img-wrapper img{
  height: 100%;
  width: 100%;
  object-fit:fill;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
}

.service-card-detail {
  height: 55%;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  align-items: center;
  padding: 10px 30px;
}
.service-card-detail h3 {
  font-size: 22px;
}
.service-card-detail h3,
p {
  text-align: center;
  font-weight: 500;
}
.slick-slide > div {
  margin: 0px 28px;
}
.card-head{
  font-weight: 900 !important;
}
@media only screen and (max-width: 1024px) {
  .services-card {
    width: 256px;
    height: 384px;
    border-radius: 40px;
    background-color: white;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.29);
  }
}

@media only screen and (max-width: 500px) {
  .slick-slide > div {
    margin: 0px 63px;
  }
  .slick-prev {
    left: 0px;
}
.slick-next{
  right: 0px;
}
  .services-card {
    width: 218px;
    height: 329px;
    margin: auto;
  }

.card-head{
  font-size: 17px !important;
  font-weight: 900 !important;
}
.card-para{
  font-size: 13px;
}
}
@media only screen and (max-width: 400px) {
  .slick-slide > div {
    margin: 0px 50px;
  }
}
@media only screen and (max-width: 350px) {
  .slick-slide > div {
    margin: 0px 17px;
  }
}
