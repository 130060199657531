html,
html,
body {
  font-family: 'Montserrat';
}

h1 {
  font-weight: 700;
  font-size: 44px;
  line-height: 70px;
}

.contcus a{
  color: #fff !important;
}

#collasible-nav-dropdown {
  color: rgb(0, 0, 0);
  font-weight: bold;
}

.navheader {
  margin: 20px 0px 0px 180px;
}

.navlink {
  font-weight: 700;
  /* margin: 20px 0px 10px 0px !important; */
  color: #000000;
  padding: 0 10px;
  text-decoration: none;

  font-family: 'Montserrat';

}

.navlink:hover {
  text-decoration: none;

}
/*folating button*/
.floating {
  position: fixed;
  width: 50px;
  height: 50px;
  bottom: 40px;
  right: 57px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
  margin-top: -40px;
 }
 
 .fab-icon {
  margin-top: 11px;
 }


.navlink:hover {
  transition: 0.5s;
  border-radius: 4px;
  border-bottom: 5px solid #006FB4;
}

/*footer */



@keyframes profile {
  0% {
    content: 'accelerate'
  }

  50% {
    content: 'accomplish'
  }

  100% {
    content: 'acquire'
  }
}

@media (max-width:768px) {
  #footer h5 {
    padding-left: 0;
    border-left: transparent;
    padding-bottom: 0px;
    margin-bottom: 10px;
  }

  .navheader {
    margin: 0;
  }

  .navlink {
    font-size: 12px;
    padding: 0px 5px;
    width: 35%;
  }

}

@media (max-width:600px) {
 .logo{
  margin: 0px !important;
 }


.floating{
  margin: 0px -9px 0px 0px;
  bottom: 10px;
}
}






