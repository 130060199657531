.loan-h1 {
    position: relative;
    top: 34%;
    left: 20%;
}

.line-1 {
    border-left: 1px solid #006FB4 !important;
    height: 199%;
    position: absolute;
    left: 28.5%;
    top: -69%;
}

.line-2 {
    position: absolute;
    border-top: 1px solid #006FB4;
    width: 289%;
    left: -124px;
    position: absolute;
    top: 61%;

}

.line-3 {
    border-left: 1px solid #006FB4 !important;
    height: 199%;
    position: absolute;
    left: 36%;
    top: -119%;
}

.hori {
    content: '';
    background-image: linear-gradient(180deg, #009B40 0%, #007A97 54.17%, #006FB4 100%);
    position: relative;
    top: 34%;
    left: -19%;
    bottom: 30px;
    width: 19%;
    height: 8px;
    border-radius: 20px;
}

.hori2 {
    content: '';
    background-image: linear-gradient(180deg, #009B40 0%, #007A97 54.17%, #006FB4 100%);
    position: relative;
    top: 29.5%;
    left: -2.3%;
    bottom: 30px;
    width: 10%;
    height: 8px;
    border-radius: 20px;
    opacity: 1;

}

.loan-sub {
    font-weight: 700;
    font-size: 35px;
    line-height: 70px;
    color: #000000;
    margin: 94px 0px 0px 141px;
}

.loan-para {
    font-weight: 500;
    font-size: 16px;
    margin: 29px 80px 0px 141px;
}

.row-loan {
    margin: 30px 120px 0px 120px;
}

.hdgstrup {
    font-weight: 700;
    font-size: 30px;
    line-height: 70px;
    margin: 80px 0px 30px 131px;
}

.hdgstrup2 {
    font-weight: 700;
    font-size: 30px;
    line-height: 70px;
    margin: 80px 0px 10px 131px;
}

.hdgstrup3 {
    font-weight: 500;
    font-size: 28px;
    line-height: 70px;
    margin: 10px 0px 10px 131px;
}

.srtuppara {
    font-weight: 500;
    font-size: 16px;
    text-align: left;
    margin: 20px 120px 30px 131px;
}

.txt-blod {
    font-weight: 700;
    font-size: 16px;
    margin: 20px 120px 10px 131px;
}

.txt-normal {
    font-size: 14px;
    line-height: 20px;
    margin: 20px 120px 40px 131px;
}

/*startup cicle*/
.srtu-row {
    margin: 0px 110px 0px 0px;
}

.srtu-row2 {
    margin: 0px -79px 0px 0px;
}

.sru-row3 {
    margin-left: -119px;
}

.sru-row4 {
    margin-left: 25px;
}

.sru-row5 {
    margin: 0px -22px 0px 0px;
}

.srtu-row6 {
    margin-left: -27px
}

.strtupdiv {
    margin: 36px 0px 296px 62px;
    display: flex;
}

.strtupdiv2 {
    margin: 18px 0px 277px 273px;
    display: flex;
}

.strtupdiv3 {
    margin: 0px 0px 60px 0px;
    display: flex;
}

.startup1 {

    width: 152px;
    height: 152px;
        border-radius: 50%;
        display: flex; /* or inline-flex */
        align-items: center; 
        justify-content: center;
        font-size: 13px;
}
.pop-para{
    font-size: 13px;
  }
 .popup-content {
background: #006FB4;
padding:15px;
color:white;
 }
 

.color1 {
    /* background: rgba(0, 155, 64, 0.5); */
    background: #009B40;
}
.color1:hover{
    background: #006FB4;
}

.color2 {
    /* background: rgba(0, 111, 180, 0.5); */
    background: #006FB4;
}
.color2:hover{
    background: #009B40;
}
.startup1 h5 {
    margin-top: 20px;
    font-weight: 600;
    font-size: 14px;
}

.statiphdg {
    margin-top: 37px !important;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 41px !important;
}

.startup1 p {
    font-size: 12px;
    line-height: 14px;
    padding: 0px 3px 0px 24px;
}

.vertlin {
    width: 13px;
    height: 43px;
    background: rgba(0, 111, 180, 0.5);
    border-radius: 30px;
    margin: -12px 145px;
}

.vertlin2 {
    width: 13px;
    height: 43px;
    background: rgba(0, 111, 180, 0.5);
    border-radius: 30px;
    margin: -308px 0px 0px 137px;
}

.brodline {
    width: 1146px;
    height: 17px;
    background: rgba(0, 111, 180, 0.5);
    border-radius: 30px;
    margin: -293px 0px 0px 202px;
}

.brodline2 {
    width: 1263px;
    height: 17px;
    background: rgba(0, 111, 180, 0.5);
    border-radius: 30px;
    margin: -293px 0px 0px 156px;

}

.brodline3 {
    width: 116px;
    height: 17px;
    background: rgba(0, 111, 180, 0.5);
    border-radius: 30px;
    margin: -293px 0px 0px 156px;
}

.rectdiv {
    margin: 40px 0px 60px 140px;
}

.rect1 {
    border: 1px solid #009B40;
    width: 235px;
    height: 235px;
}

.rect2 {
    margin-top: 12px;
    width: 210px;
    height: 210px;
    border: 1px solid #009B40;
}

.rect2:hover{
    border: 1px solid #006FB4;
}
.rect2:hover{
    border: 1px solid #006FB4;
}
.rect-para:hover {
    color: #009B40;
}
.rect-para {
    padding: 35px 3px 0px 3px;
    font-size: 16px;
    color: #006FB4;
}

.overrow2 {
    margin-top: 60px;
}

.startup {
    border: 1px solid #006FB4;
    padding: 30px;
    width: 280px;
    /* background: rgba(0, 155, 64, 0.5); */
    height: 280px;
    border-radius: 50%;
}



.vertline {
    /* height: 35px; */
    height: 25px;
    width: 10px;
    border-radius: 50px;
    background: #006FB4;
    /* background: rgba(0, 111, 180, 0.5); */
    margin: -17px 141px;
    opacity: 1;
}
/* .overf1{
  margin-left: 128px;
} */
.overf2{
   margin-left: -211px; 
}

.startup p {
    font-size: 12px;
    line-height: 14px;
    margin-top: 12px;
}

.startup h5 {
    font-size: 14px;
    font-weight: bold;
    margin: -8px 0px 12px 0px;
}

.startup2 {
    border: 1px solid #006FB4;
    padding: 30px;
    width: 300px;
    height: 300px;
    border-radius: 50%;
}

.startup2 h5 {
    font-size: 14px;
    font-weight: bold;
}

.startup2 p {
    font-size: 12px;
    line-height: 14px;
}

.vertline3 {
    height: 12px;
    width: 139px;
    border-radius: 50px;
    background: #006FB4;
    position: absolute;
    left: 61%;
    top: 32%;
    opacity: 1;
}

.vertline2 {
    height: 35px;
    width: 10px;
    border-radius: 50px;
    /* background: rgba(0, 155, 64, 0.5); */
    background: #009B40;
    margin: -17px 141px;
    opacity: 1;

}

.strupFinding {
    margin-right: 268px !important;
}

.cardsCircle {
    padding-top: 60px !important;
}

.Content {
    margin-right: -8px !important;
}

.borderLine {
    border: 10px solid #009B40;
    border-radius: 50px;
    width: 102%;
    opacity: 1;
    background: #009B40;
}

.borderLine2 {
  border: 10px solid #006FB4; 
    border-radius: 50px;
    width: 90%;
    margin-left: 66px;
    opacity: 1;
    background: #006FB4;
}

.lines-border{
    border: 10px solid #009B40;
}
.lines-border2{
    border: 10px solid #006FB4;
}
.imgbloan {
    background-image: url("../images/bloan.png");
}

.imgbloan:hover {
    background-image: url("../images/hbloan.png");

}

.sudo:hover {
    background-image: url("https://media.geeksforgeeks.org/wp-content/uploads/rk.png");
}

.float {
    position: fixed;
    width: 75px;
    height: 60px;
    bottom: 100px;
    right: 40px;
    background-color: #25d366;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 18px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
}

.startFubds {
    margin-left: 100px;
    margin-right: 100px;
}

button.btn-close {
    background: transparent url('../images/x-square.svg') !important;
}

.my-float {
    margin-top: 16px;
}

.colm-gap {
    column-count: 3;
    column-gap: -50px !important;
}

.forscol {
    column-count: 4;
}

.mrgtp {
    margin-top: -274px;
}
.box-shadow{
    border: 6px solid #006FB4;
    padding: -9px;
}
@media screen and (max-width:768px) {
    .borderLine {
        width: 99%;
    }

    .startup {
        width: 130px;
        height: 130px;
        margin-bottom: 15px;
    }
    .smalcir {
        /* width: 240px !important;
        height: 240px !important; */
        margin-bottom: 15px;
        font-size: 14px;
    }
    .smalcir2 {
        /* width: 260px !important;
        height: 260px !important; */
        margin-bottom: 15px;
        font-size: 14px;
    }
    .smalcir h6{
        margin-top: 20px;
        font-size: 14px;
    }
    /* .vertline3 {
        width: 44px;
        left: 64%;
    } */
    .startup h5,
    .startup2 h5 {
        margin: -17px 0px 12px 0px !important;
    }
    .hedstr{
       padding: 10px !important;
    }
    .hedstr2{
        padding: 2px !important;
    }
    
    /* .startup p {} */

    /* .vertline3 {
        width: 153px;
        left: 282px;
    } */

    .startup2 {
        width: 230px;
        height: 230px;
        margin-bottom: 15px;
        border-radius: 50%;
    }

    .startup2 p,
    .startup p {
        font-size: 7px;
    }


    #loan {
        border: none;
        /* height: 360px; */
    }

    .mrgtp {
        margin-top: 0;
    }

    .loan-sub {
        /* top: -12%; */
        /* left: 0; */
        margin: 40px 0px 0px 31px;
        font-size: 34px;
    }

    .hori {
        top: -3%;
        left: -40%;
    }

    .loan-para {
        /* margin: -60px 122px 0px 0px; */
        font-size: 15px;
    margin: 29px 80px 0px 34px;
    }

    .hori2 {
        top: -10.4%;
        left: -23.3%;
    }

    .loan-h1 {
        top: 0;
        left: 0;
    }

    #loan2 {
        width: 0;
        height: 0;
        border: none;
    }

    .hdgstrup {
        font-size: 26px;
    }

    .hdgstrup2 {
        font-size: 26px;
        margin-bottom: 10px !important;
    }

    .hdgstrup3 {
        font-size: 26px;
        margin-bottom: 10px !important;

    }

    .srtuppara {
        font-size: 15px;
    }
    .imgbloan ,.imghloan,.imgploan{
        height: 200px;
    }
  .rect1{
    height: 200px;
    width: 200px;
  }
  .rect2{
    width: 182px;
    height: 182px;
  }
  .txt-normal{
    font-size: 14px;
    margin-bottom: 20px;
  }
  .rect-para{
    font-size: 14px;
  }
  .overf2{
    /* margin: 0px; */
    left: 11px;
  }
  .float{
    margin-right: -53px;
  }

}
@media screen and (min-width:766px) and (max-width:769px) {
    .vertline3 {
        margin-left: -14px;
        width: 120px;
    }
  /*    .about-desc {
       margin: 0px 60px 30px 0px !important;
    } */
}
@media screen and (max-width:600px) {

    html,
    body {
        overflow-x: hidden !important;
    }

    .smalcir,.smalcir2{
        margin: 10px;
    }
    .startup {
        width: 320px;
        height: 320px;
        margin-bottom: 15px;
        justify-content: center;
    }
    .smalcir h5{
        font-size: 15px;
    }
    .smalcir h6{
        margin-top: 20px;
    }
    .borderLine2 {
        width: 47%;
    }
   /*  .overf1{
        margin-left: 20px; 
    }*/
    /* .vertline3{
        width: 14px;
        left: 48%;
        top: 108px;
    } */

   .loan-para {
    margin: 10px 20px 0px 10px;
    font-size: 11px;
}

.loan-sub{
    margin: 0;
}
    .startup p {
        font-size: 8px;
    }

    .vertline3 {
        /* height: 30px;
        top: 87%;
        margin-left: -107px;
        left: 49%; */
        height: 30px;
    top: 79%;
    margin-left: -126px;
    }
     .overf1{
        margin: 10px;
     }
   
    .startup2 {
        margin-bottom: 15px;
        justify-content: center;
        width: 300px;
        height: 300px;
    }

    .startup h5,
    .startup2 h5 {
        margin: -5px 0px 12px 0px !important;
    }

    .startup2 p {
        font-size: 12px;
    }

    .float {
        width: 50px;
        height: 50px;
        font-size: 12px;
        right: 9%;
        top: 74%;
    }
    .loan-para {
        margin: 0px 40px 0px 0px;
    }

    .loan-circle {
        margin: 0;
    }

    #loan {
        border: none;
        /* height: 360px; */
        width: 320px;
    }

    .hori2 {
        top: -9.7%;
    }

    .hdgstrup,
    .txt-normal,
    .txt-blod,
    .hdgstrup2,
    .hdgstrup3,
    .srtuppara {
        margin: 15px 0;
    }

    .startup p {
        font-size: 12px;
        text-align: center;
    }

    .rect-para {
        font-size: 12px;
        padding: 53px 13px 0px 13px;
    }

    .revercol {
        flex-direction: column-reverse;
    }

    .Content {
        justify-content: center;
        align-items: center;
    }

    .txt-normal {
        font-size: 12px;
    }
    .srtuppara{
        font-size: 12px;
    }
    .vertline, .vertline2 {
        /* width: 175px; */
        margin: -9px px;
    }
    .smalcir p, .smalcir2 p {
        margin: 0;
        font-size: 10px;
    }
}


@media only screen and (max-width: 588px) and (min-width: 320px)  {
    .overf2 {
        margin: 0px 0px 0px 10px;
        position: relative;
        left: 120px;
        /* /* right: -121px; */
    }
}

@media screen and (max-width:1024px) {
    body,html{
        overflow-x: hidden !important;
      }
    .vertline3 {
    width: 121px;
    left: 74%;
    }
    .float{
        right: 99px;
    }
    .overf2 {
        margin-left: -114px;
    }
  }

  .smalcir{
    border: 1px solid #006FB4;
    /* width: 100px; */
    /* background: rgba(0, 155, 64, 0.5); */
    /* height: 100px; */
    /* width: 256px;
    height: 256px; */
    width: 152px;
    height: 152px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-weight: bold;
    margin: 0px 10px 10px 10px ;
    color: #FFF;
  }
  
  .smalcir h6{
    font-size: 14px;
    padding: 2px;
  }
  .smalcir2 h6{
    font-size: 14px;
  }
  .smalcir2{
    border: 1px solid #006FB4;
    /* width: 100px; */
    /* background: rgba(0, 155, 64, 0.5); */
    /* height: 100px; */
    /* width: 246px;
    height: 246px; */
    width: 152px;
    height: 152px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-weight: bold;
    margin: 0px 10px 0px 10px ;
    color: #FFF;
  }
  .smalcir p,.smalcir2 p{
    padding: 0px 15px 0px 10px;
    font-size: 11px;
  }
  .pop-para{
    font-size: 13px;
  }
  @media screen and (max-width:1440px) {
    .overf2 {
        position: relative;
        right: 65px;
    }
    }